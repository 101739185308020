<script setup lang="ts">
import { defineLayout } from '#imports'
import Lenis from 'lenis'

export interface MenuItem {
  title: string
  to?: string
  icon?: string
  about?: string
  children?: MenuItem[]
}

defineLayout({
  label: 'Logicbomb Media',

  grid: {
    default: {
      container: false,
      columns: 6,
      gutter: '2rem',
    },
    lg: {
      container: true,
      columns: 12,
      gap: '4rem', // .gap-8
      gutter: '2rem',
    },
  },
})

// Generated much of this at https://realfavicongenerator.net
useHead({
  htmlAttrs: {
    lang: 'en-US',
  },
  meta: [
    { name: 'msapplication-TileColor', content: '#000000' },
    { name: 'msapplication-config', content: '/favicons/lbm-marketing/browserconfig.xml' },
    { name: 'theme-color', content: '#000000' },
    { property: 'og:title', content: 'LBM: Enterprise web development for applications and marketing' },
    { property: 'og:description', content: 'LBM partners with enterprises to imagine, engineer, and deploy innovative digital solutions. Since 2007, we\'ve empowered brands with custom content management and dynamic applications that drive exceptional experiences and lasting impact.' },
    { property: 'og:image', content: '/imgs/og-image-lbm-base.jpg' },
    { property: 'og:url', content: 'https://voix.ooo' },
    { property: 'og:type', content: 'website' },
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:title', content: 'LBM: Enterprise web development for applications and marketing' },
    { name: 'twitter:description', content: 'LBM partners with enterprises to imagine, engineer, and deploy innovative digital solutions. Since 2007, we\'ve empowered brands with custom content management and dynamic applications that drive exceptional experiences and lasting impact.' },
    { name: 'twitter:image', content: '/imgs/og-image-lbm-base.jpg' },
  ],
  link: [
    { rel: 'apple-touch-icon', sizes: '180x180', href: '/favicons/lbm-marketing/apple-touch-icon.png' },
    { rel: 'icon', type: 'image/png', sizes: '96x96', href: '/favicons/lbm-marketing/favicon-96x96.png' },
    { rel: 'icon', type: 'image/svg', href: '/favicons/lbm-marketing/favicon.svg' },
    { rel: 'manifest', href: '/favicons/lbm-marketing/site.webmanifest' },
    { rel: 'mask-icon', href: '/favicons/lbm-marketing/safari-pinned-tab.svg', color: '#d65f33' },
    { rel: 'shortcut icon', href: '/favicons/lbm-marketing/favicon.ico' },
    { rel: 'preconnect', href: 'https://fonts.gstatic.com', crossorigin: '' },
  ],
})

const menuItems: MenuItem[] = [
  {
    title: 'Projects',
    to: '/#projects',
  },
  {
    title: 'About',
    to: '/#about',
  },
  {
    title: 'Contact',
    to: '/#contact',
  },
  {
    title: 'Articles',
    to: '/blog',
  },
]

const progress = ref(0)
const contentHeight = ref(0)
const windowHeight = ref(0)

const percentComplete = computed(() => {
  return (progress.value / (contentHeight.value - windowHeight.value)) * 100
})

provide('scrollData', { progress, contentHeight, windowHeight, percentComplete })

const lenis: Ref<null | Lenis> = ref(null)

onMounted(() => {
  contentHeight.value = document.body.scrollHeight
  windowHeight.value = window.innerHeight

  lenis.value = new Lenis({
    autoRaf: true,
  })

  lenis.value.on('scroll', (e) => {
    progress.value = e.targetScroll
  })
})

provide('lenis', lenis)
</script>

<template>
  <div id="app" class="relative min-h-[100vh] bg-stone-950 text-stone-100 font-lbm-sans logicbomb-theme">
    <div class="relative z-40">
      <div id="portal-modal" />
    </div>
    <div class="mx-auto">
      <div class="relative z-40">
        <LbmNavigation :menu-items="menuItems" />
      </div>

      <slot />

      <LbmFooter :menu-items="menuItems" />
    </div>
  </div>
</template>

<style>
@import url('@/assets/css/lbm-main.scss');
</style>
